import { Dialog } from '@mui/material';
import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import './VIdeoPlayerModal.css';
import video from '../../images/Video1.MOV';

type LoginProps = {
  open: any;
  handleClose: any;
};

export const VideoPlayerModal: React.FC<LoginProps> = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} id="dialog4">
      <div style={{ width: '100%' }}>
        <video width="100%" height="600" controls>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </Dialog>
  );
};
