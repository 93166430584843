import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
// import { TbFaceIdError } from 'react-icons/tb';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './404.css';

export default function Error() {
  const { t, i18n } = useTranslation();
  const [load, setLoad] = useState(false);
  const currentLang = i18n.language;
  useEffect(() => {
    setTimeout(() => {
      setLoad((e) => !e);
    }, 400);
  }, []);
  return (
    <div>
      <Header />
      {load && (
        <div id="div">
          <div>
            <p style={{ textAlign: 'center' }}>
              {/* <div className="errorImage">
              <TbFaceIdError />
            </div> */}
              <p className="errorTitle">{t('error_title')}</p>
              <Link to={`/${currentLang}/`} className="errorLink">
                {t('error_title1')}
              </Link>
            </p>
          </div>
        </div>
      )}
      {!load && (
        <div className="loading">
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </div>
      )}
    </div>
  );
}
