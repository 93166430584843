import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { About } from '../../pages/About/About';
import { Contacts } from '../../pages/Contacts/Contacts';
import { Home } from '../../pages/Home/Home';
import { MyOrder } from '../../pages/MyOrder/MyOrder';
import { Order } from '../../pages/Order/Order';
import { OrderConfirm } from '../../pages/OrderConfirm/OrderConfirm';
import { Section } from '../../pages/Section/Section';
import { User } from '../../pages/User/User';
import { ProductDetails } from '../../pages/ProductDetails/ProductDetails';
import Error from '../../pages/404/404Page';
import Services from '../../pages/Services/Services';
import Service from '../../pages/Service/Service';
import Category from '../../pages/Categoryes/Category';
import Shipment from '../../pages/Shipment/Shipment';
import Printing from '../../pages/Printing/Printing';
import { useTranslation } from 'react-i18next';

function Routs() {
  const { pathname } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();

  const x = pathname.split('/');

  if (x[1] !== language) {
    x[1] = language;
    const y = x.join('/');
    localStorage.setItem('i18nextLng', language);
    window.location.replace(y);
  }

  return (
    <Routes>
      <Route path={`/${language}`} element={<Home />} />
      <Route path={`/${language}/home/`} element={<Home />} />
      <Route path={`/${language}/category/:id/*`} element={<Section />} />
      <Route path={`/${language}/product/:code/`} element={<ProductDetails />} />
      <Route path={`/${language}/category/`} element={<Category />} />
      <Route path={`/${language}/about/`} element={<About />} />
      <Route path={`/${language}/contacts/`} element={<Contacts />} />
      <Route path={`/${language}/card/`} element={<Order />} />
      <Route path={`/${language}/cardconfirm/`} element={<OrderConfirm />} />
      <Route path={`/${language}/user/`} element={<User />} />
      <Route path={`/${language}/user/myorders/`} element={<MyOrder />} />
      <Route path={`/${language}/services/:id/`} element={<Services />} />
      <Route path={`/${language}/service/:code/`} element={<Service />} />
      <Route path={`/${language}/shipment/`} element={<Shipment />} />
      <Route path={`/${language}/printing/`} element={<Printing />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default Routs;
