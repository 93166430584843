import React from 'react';
import './i18nMobile.css';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function languageMobile() {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const changeLang = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="languageMobile">
      <div className="languageM">
        <button
          className={clsx('LinkActiveMobile', currentLang === 'hy' && 'LinkMobile')}
          onClick={() => changeLang('hy')}
        >
          ՀԱՅ
        </button>
        <button
          className={clsx('LinkActiveMobile', currentLang === 'ru' && 'LinkMobile')}
          onClick={() => changeLang('ru')}
        >
          РУС
        </button>
        <button
          className={clsx('LinkActiveMobile', currentLang === 'en' && 'LinkMobile')}
          onClick={() => changeLang('en')}
        >
          ENG
        </button>
      </div>
    </div>
  );
}
