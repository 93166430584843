import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from './ENG/en';
import ru from './RUS/rus';
import hy from './ARM/hy';

const resources = {
  hy: {
    translation: hy,
  },
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

const _lng = localStorage.getItem('i18nextLng');
if (!_lng) {
  localStorage.setItem('i18nextLng', 'hy');
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['hy', 'en', 'ru'],
    resources: resources,
    detection: {
      caches: ['cookie'],
    },
  });

export default i18n;

/*// i18n
//   .use(detector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: ['hy', 'ru', 'en'],
//     resources,
//     // interpolation: {
//     //   escapeValue: true,
//     // },
//     detection: {
//       caches: ['cookie'],
//     },
//   });*/
