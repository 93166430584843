import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { CartBlock } from '../../components/CartBlock/CartBlock';
import { Header } from '../../components/Header/Header';
import { SectionContent } from '../../components/SectionContent/SectionContent';
import { SectionHeading } from '../../components/SectionHeading/SectionHeading';
import SubCategory from '../../components/SubCategory/SubCategory';
import CartCategoryes from '../../http/cadegoryes/categoryes';
import './Section.css';

interface Provider {
  name: string;
  image: string;
  children: any;
  data: any;
}

export const Section = () => {
  const [data, setData] = useState<Provider>();
  const result: any[] = [];
  const [nameData, setNameData] = useState(result);
  const { id } = useParams();
  const params = useParams();

  const x: any[] = [];
  Object.keys(params).forEach((key) => {
    if (key !== 'id') {
      params[key]
        ?.toString()
        .split('/')
        .forEach((e) => {
          if (e !== '') {
            x.push(e);
          }
        });
    }
  });

  const _id = x.length > 0 ? x[x.length - 1] : id;

  useEffect(() => {
    CartCategoryes.addCategoryes(_id)?.then((e: any) => setData(e?.data));
  }, [_id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  const __childarr = data?.data ?? {};

  function Values(obj: any) {
    for (let i = 0; i < x?.length; i++) {
      if (Object.keys(obj)?.length > 0 && nameData?.length <= x.length) {
        nameData.push(obj?.name);
      }
      if (obj?.children) {
        Values(obj?.children[0]);
      }
    }
  }
  Values(__childarr);
  const dataName = [];
  dataName.push(data?.data?.name);

  return (
    <>
      {
        <div className="section_page">
          <div className="section_page_head">
            <Header />
          </div>
          {data?.data && (
            <SectionHeading
              data={data?.data}
              name={data?.data?.name}
              src={data?.data?.image ? data?.data?.image : ''}
            />
          )}
          {!data?.data?.children && data?.data ? (
            <SectionContent names={dataName.length == 1 ? dataName : nameData} />
          ) : (
            <div>
              <SubCategory dataChildrens={data?.data?.children} />
            </div>
          )}
        </div>
      }
    </>
  );
};
