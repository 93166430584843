export default {
  nav_item: 'Home',
  nav_item1: 'About Us',
  nav_item2: 'Contact Us',

  header_buttons_title: 'Sign in',
  header_buttons_title1: 'Sign up',

  head_title: 'The Best For You',
  heat_subtitle: 'Nothing is impossible with us',
  head_button_title: 'See more',

  section1_title: 'NEW COLLECTION',

  section2_title: 'Sections',
  section2_title_subtitle: 'When the quality of work speaks louder than words․․․',
  section2_button_title: 'See more',

  footer_info_title: 'ARMALIS',
  footer_info_subtitle: 'High quality, fast service and individual approach.',
  footer_assortment_title: 'Assortment',
  footer_services_title: 'Services',
  footer_paymont_title: 'Payment method',
  footer_paymont_items: 'Cash',
  footer_paymont_items1: 'Transfer',
  footer_contacts_title: 'Contact Us',

  about_title: 'We work with great love and responsibility',
  aboutUs_title: 'About Us',
  aboutUs_content_text:
    'ArmAlis company was founded in <span class="orange">2012թ</span> by the founding director <span class="orange">Vardan Margaryan</span>. Thanks to selfless, conscientious, disciplined, hard work and dedicated, experienced specialists, this company has expanded in 10 years across the regions and cities of the republic, as the people say, it has grown, prospered, flourished. For 10 years, thanks to ArmAlis, thousands of Armenian men have stayed close to their families and could not earn money abroad. For <span class="orange">10 years</span>, many apartments have debuted with furniture made from our materials, which are imported from Russia, From Persia and other countries. We guarantee quality and fast service, and the variety of the assortment provides. We take an individual approach to each client.',

  achievement_text:
    'New achievements, new markets, new partners. ArmAlis is a company that does not need advertising. ArmAlis company is a family that will provide you with high-quality materials today, tomorrow and forever.',

  contacs_title: 'We work with great love and responsibility',

  feedBack_title: 'feedback',

  contactForm_name: 'Name',
  contactForm_lastname: 'Surname',
  contactForm_phone: 'Phone',
  contactForm_email: 'Email',
  contactForm_message: 'Message',
  contactForm_submit: 'Send',

  contactForm_name_required: 'Enter Your Name',
  contactForm_lastname_required: 'Enter Your Last Name',
  contactForm_phone_required: 'Enter Your Phone Number',
  contactForm_email_required: 'Enter Your Email Address',
  contactForm_message_required: 'Enter Your Message',

  contactForm_message_toast_success: 'Your message has been sent successfully.',
  contactForm_message_toast_error: 'Your message has not been sent.',

  details_title: 'Add To Cart',
  detailsText: 'Size',
  detailsText1: 'Type',
  detailsText2: 'Thickness',
  detailsText3: 'Code',
  detailsText4: 'Color',

  sectionHeading_text: 'a wide range of countertops',
  sectionHeading_text1: 'Ձեր ընտրած բաժնում չկան ապրանքներ',

  sectionContent_title: 'Sections',
  sectionContent_title1: 'Table covering',
  sectionContent_categoryes_title: 'Classify By:',
  sectionContent_options: 'New assortment',
  sectionContent_options1: 'Price: From Cheap To Expensive',
  sectionContent_options2: 'Price: From Expensive To Cheap',
  sectionContent_options3: 'Stocks',
  sectionContent_price_range_title: 'Cost',
  sectionContent_panel_title: 'Size',
  sectionContent_panel_title1: 'Material',
  sectionContent_panel_title1_text: 'Granite',
  sectionContent_panel_title1_text1: 'Tree',
  sectionContent_panel_title1_text2: 'MDF',
  sectionContent_panel_title1_text3: 'Marble',
  sectionContent_panel_title2: 'Thickness',
  sectionContent_panel_title3: 'Color',
  sectionContent_panel_title3_text: 'White',
  sectionContent_panel_title3_text1: 'Cream',
  sectionContent_panel_title3_text2: 'Black',
  sectionContent_panel_title3_text3: 'Apple',
  sectionContent_panel_title3_text4: 'Wenge',
  sectionContent_panel_title3_text5: 'Green',
  sectionContent_panel_title3_text6: 'Blue',
  sectionContent_panel_title3_text7: 'Anthracite',
  sectionContent_panel_title4: 'Country of Origin',
  sectionContent_panel_title4_text: 'Russia',
  sectionContent_panel_title4_text1: 'Iran',
  sectionContent_panel_title4_text2: 'Turkey',
  sectionContent_panel_title5: 'Services',
  sectionContent_panel_title5_text: 'Cut',
  sectionContent_panel_title5_text1: 'PVS',
  sectionContent_panel_title5_text2: 'Shipment',
  sectionContent_panel_title5_text3: 'Printing',
  sectionContent_panel_text_all: 'All',
  sectionContent_panel_search_text: 'Code',
  sectionContent_empoty_text: 'Nothing found.',

  cartBlock_see_more_card: 'See more',

  login_dialog_title: 'LOG IN',
  login_dialog_emaile: 'Email',
  login_dialog_password: 'Password',
  login_dialog_forget_title: 'Forgot your password?',
  login_dialog_emaile_error: 'Please enter your email',
  login_dialog_password_error: 'Please enter your password',

  dialog_head_back_title: 'Back',
  dialog_head_subTitle:
    "It's the order of Latin words that they're placed in, they don't make sentences.",
  dialog_label: 'Email',
  dialog_content_btn_text: 'Reset password',
  dialog_emaile_error: 'Please enter your email',
  dialog_emaile_error_text: 'Email must be a valid email',

  signUp_dialog_title: 'Register',
  signUp_dialog_label: 'Name',
  signUp_dialog_label1: 'Surname',
  signUp_dialog_label2: 'Phone number',
  signUp_dialog_label3: 'Email',
  signUp_dialog_label4: 'City',
  signUp_dialog_label5: 'Password',
  signUp_dialog_label6: 'Repeat password',
  signUp_dialog_content_btn: 'Register',
  signUp_dialog_label_error: 'Enter Your Name',
  signUp_dialog_label1_error: 'Enter Your Last Name',
  signUp_dialog_label2_error: 'Enter Your Phone Number',
  signUp_dialog_label3_error: 'Enter Your Email Address',
  signUp_dialog_label4_error: 'Enter Your City',
  signUp_dialog_label5_error: 'Enter Your Password',
  signUp_dialog_label6_error1: 'Repeat Your Password',
  signUp_dialog_label6_error2: "Passwords don't match",

  emptyCardContent_title: 'The basket is empty.',
  emptyCardContent_subTitle:
    'This is by far the most popular text of the installer, even if there are different variants other than the order in which Latin words are repeated.',

  productDetails_similarTitle: 'similar products',
  productDetails_btn_see_more: 'See More',

  moblie_menu_text: 'Home',
  moblie_menu_text1: 'About Us',
  moblie_menu_text2: 'Contact Us',

  cartSlice_text: 'Product successfully added to cart.',
  cartSlice_text1: 'You cannot add it to the cart.',
  cartSlice_text2: 'Successfully deleted.',

  loginSlice_text: 'You have successfully logged in to your account.',
  loginSlice_text1: 'The email address or password is incorrect.',
  loginSlice_text2: 'You have successfully registered an account.',
  loginSlice_text3: 'Please try again.',

  changeInfo_text: 'First Name',
  changeInfo_text1: 'Last Name',
  changeInfo_text2: 'Phone',
  changeInfo_text3: 'Email',
  changeInfo_text4: 'Address',
  changeInfo_text5: 'Password',
  changeInfo_text6: 'Update Profile',
  changeInfo_error_text: 'Enter your Name',
  changeInfo_error_text1: 'Enter your Last Name',
  changeInfo_error_text2: 'Enter your Phone Number',
  changeInfo_error_text3: 'Email must be a valid email',
  changeInfo_error_text4: 'Enter your Email',
  changeInfo_error_text5: 'Enter your Address',
  changeInfo_error_text6: 'Enter your Password',

  changeInfo_password_text: 'Old Password',
  changeInfo_password_text1: 'New Password',
  changeInfo_password_text2: 'Retype New Password',
  changeInfo_password_text3: 'Update Password',
  changeInfo_password_error_text: 'Enter Your Old Password',
  changeInfo_password_error_text1: 'Enter Your New Password',
  changeInfo_password_error_text2: 'Repeat Your New Password',
  changeInfo_password_error_text3: 'Passwords do not match',
  changeInfo_password_true: 'You have successfully changed your password.',
  changeInfo_password_false: 'Your password is wrong.',

  userContent_title: 'My Profile',
  userContent_title1: 'My Orders',
  userContent_title2: 'Log Out',

  orderContent_title: 'Product Name',
  orderContent_title1: 'Cost',
  orderContent_title2: 'Quantity',
  orderContent_button: 'To order',

  till_title: 'Cash',
  till_title1: 'Total',
  till_title2: 'Product Name',
  till_button: 'To order',
  till_input_text: 'First Name',
  till_input_text1: 'Last Name',
  till_input_text2: 'Phone',
  till_input_text3: 'Email',
  till_input_text4: 'Address',
  till_error_text: 'Enter your Name',
  till_error_text1: 'Enter your Last Name',
  till_error_text2: 'Enter your Phone Number',
  till_error_text3: 'Email must be a valid email',
  till_error_text4: 'Enter your Email',
  till_error_text5: 'Enter your Address',

  searchInput_text: 'Search',

  myorders_text: 'Your order has been successfully placed.',

  error_title: 'Page Not Found',
  error_title1: 'Go to the Home page.',

  about_sectionContent_text: 'Your search result',

  instocks_text: 'is available',
  instocks_text1: 'not available',

  serviceCart_price: 'PCS',
  serviceCart_price1: 'meter',

  about_shipment_text:
    'Delivery service is available throughout the territory of the Republic of Armenia.Delivery in Yerevan is free, and the cost of delivery to the regions varies depending on the distance.',
  about_printing_text:
    'We do printing laminate, glass, canvas, leather, MDF, PVC. We accept individual and wholesale orders.',
  shipment: 'Shipment ',
  printing: 'printing',
  error_invalid_email: 'There is already a registered user with this email',
  how_find_us: 'How to find us ?',
  work_day: 'WORKING DAY IN ARMALIS',
  address: 'Ararat region, Arbat village 4th street-54',
};
