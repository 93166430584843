import React from 'react';
import { Header } from '../../components/Header/Header';
import Categoryes from './Categoryes';

export default function Category() {
  return (
    <div>
      <Header />
      <Categoryes />
    </div>
  );
}
