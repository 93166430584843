import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import SectionHead from '../../images/SectionHead.png';
import './SectionHeading.css';
// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import CartCategoryes from '../../http/cadegoryes/categoryes';

type SectionData = {
  data?: any;
  name?: any;
  src?: any;
};

export const SectionHeading: FC<SectionData> = ({ data, name, src }) => {
  // let filterProducts = useSelector((state: any) => state?.filtred?.filtredProduct?.data);
  // const filterCategoryImage = useSelector((state: any) => state?.productType?.productTypes);
  // const x = filterCategoryImage?.filter((e: any) => e?.name == filterProducts?.[0]?.category);
  // filterProducts = filterProducts?.[0]?.category;

  // const [data, setData] = useState({ name: '', image: '' });
  // const { id } = useParams();
  // useLayoutEffect(() => {
  //   CartCategoryes.addCategoryes(id).then((e: any) => setData(e?.data));
  // }, [id]);

  const { t } = useTranslation();

  return (
    <div className="sectionHeading">
      <div className="sectionHeading_body">
        <div className="sectionHeading_text_block">
          <p className="sectionHeading_text">
            {data ? `${name} ${t('sectionHeading_text')}` : `${t('sectionHeading_text1')}`}
          </p>
        </div>
        <div className="sectionHeading_img_block">
          {src && <img src={src} alt="SectionImage" />}
        </div>
      </div>
    </div>
  );
};
