import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './SubCategory.css';

type Children = {
  dataChildrens?: any;
};

interface Provider {
  name: string;
  image: string;
  children: any;
  id: any;
  data: any;
}

const SubCategory: React.FC<Children> = ({ dataChildrens }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const subCodegory = (dataChildren: any) => {
    let _links = params['id'] + '/';
    Object.keys(params)?.forEach((element: any) => {
      if (element !== 'id' && params[element] != '' && params[element] != '/') {
        _links += params[element];
      }
    });
    _links += dataChildren + '/';
    navigate(`/${currentLang}/category/${_links}`);
  };

  return (
    <div className="subCategorie">
      {dataChildrens?.length > 0 &&
        dataChildrens?.map((e: any) => (
          <div className="subCadegories" key={e?.id}>
            <img
              className="subCadegoriesImage"
              src={e?.image}
              onClick={() => subCodegory(e?.children ? e?.children[0]?.id : e?.id)}
            />
            <p className="subCadegoriesText">{e?.name}</p>
          </div>
        ))}
      {/* <div className="subCadegories">
        <img
          className="subCadegoriesImage"
          src={dataChildren?.image}
          onClick={() => subCodegory(dataChildren?.children[0]?.id)}
        />
        <p className="subCadegoriesText">{dataChildren?.name}</p>
      </div> */}
    </div>
  );
};

export default SubCategory;
