export default {
  nav_item: 'Գլխավոր',
  nav_item1: 'Մեր Մասին',
  nav_item2: 'Կապ',

  header_buttons_title: 'Մուտք',
  header_buttons_title1: 'Գրանցվել',

  head_title: 'Լավագույնը Ձեզ Համար',
  head_subtitle: 'Մեզ հետ անհնարին ոչինչ չկա',
  head_button_title: 'Տեսնել Ավելին',

  section1_title: 'Նոր Տեսականի',

  section2_title: 'Բաժիններ',
  section2_title_subtitle: 'Երբ աշխատանքի որակը խոսում է ավելի բարձր քան խոսքերը․․․',
  section2_button_title: 'Տեսնել Ավելին',

  footer_info_title: 'ԱՐՄԱԼԻՍ',
  footer_info_subtitle: 'Բարձր որակ , արագ սպասարկում և անհատական մոտեցում։',
  footer_assortment_title: 'Տեսականի',
  footer_services_title: 'Ծառայություններ',
  footer_paymont_title: 'Վճարման եղանակ',
  footer_paymont_items: 'Կանխիկ',
  footer_paymont_items1: 'Փոխանցում',
  footer_contacts_title: 'Կապ',

  about_title: 'Մենք աշխատում ենք մեծ սիրով եվ պատասխանատվությամբ',
  aboutUs_title: 'Մեր Մասին',
  shipment: 'Առաքում',
  printing: 'Տպագրություն',
  aboutUs_content_text:
    'ԱրմԱլիս ընկերությունը հիմանդրվել է <span class="orange">2012թ</span> ին հիմնադիր տնօրեն ՝ <span class="orange">Վարդան Մարգարյանի</span> կողմից։ Անձնուրաց, բարեխիղճ, կարգապահ, համառ աշխատանքի և նվիրված, փորձառու մասնագետների շնորհիվ, այս ընկերությունը 10 տարվա ընթացքում ընդլայնվեց հանրապետության մարզերով և քաղաքներով, ինչպես ժողովուրդն է ասում ծլեց, ծաղկեց, բարգավաճեց։ <span class="orange">10 տարի</span> շարունակ ԱրմԱլիս ընկերության շնորհիվ հազարավոր հայ տղամարդիկ մնացել են իրենց ընտանիքների կողքին և չեն բռնել արտերկրում գումար վաստակելու ճանապարհը։ 10 տարի շատ բնակարաններ իրենց նորամուտը նշել են հենց մեր նյութերից պատրաստած կահույքով, որոնք ներկրվում են Ռուսաստանից, Պարսկաստանից և այլ երկրներից։ Որակը և արագ սպասարկումը երաշխավորում ենք, իսկ տեսականու բազմազանությունը ապահովում։ Յուրաքանչյուր հաճախորդին ցուցաբերում ենք անհատական մոտեցում։',
  about_shipment_text:
    'Առաքման ծառայությունը հասանելի է Հայաստանի Հանրապետության ողջ տարածքում: Երևանում առաքումը անվճար է, իսկ մարզեր առաքման գումարը տատանվում է ըստ հեռավորության:',
  about_printing_text:
    'Կատարում ենք տպագրություն լամինատ, ապակի, կտավ, կաշի, մդֆ, պվխ։ Ընդունում ենք անհատական և մեծաքանակ պատվերներ։',
  error_invalid_email: 'նման էլ-Փոստով կա գրանցված օգտատեր',
  achievement_text:
    ' Նոր ձեռքբերումներ ,նոր շուկաներ ,նոր գործընկերներ։ ԱրմԱլիսը ընկերություն է ,որը չունի գովազդի կարիք։ ԱրմԱլիս ընկերությունը դա ընտանիք է,որը ձեզ կապահովի բարձրակարգ նյութերով՝ այսօր , վաղը եվ ընդմիշտ։',
  how_find_us: 'Ինչպե՞ս գտնել մեզ',
  contacs_title: 'Մենք աշխատում ենք մեծ սիրով եվ պատասխանատվությամբ',
  work_day: 'Աշխատանքային օրը ԱրմԱլիսում',

  feedBack_title: 'հետադարձ կապ',

  contactForm_name: 'Անուն',
  contactForm_lastname: 'Ազգանուն',
  contactForm_phone: 'Հեռախոսահամար',
  contactForm_email: 'Էլ-Փոստ',
  contactForm_message: 'Հաղորդագրություն',
  contactForm_submit: 'ՈՒղարկել',

  contactForm_name_required: 'Մուտքագրեք Ձեր Անունը',
  contactForm_lastname_required: 'Մուտքագրեք Ձեր Ազգանունը',
  contactForm_phone_required: 'Մուտքագրեք Ձեր Հեռախոսահամարը',
  contactForm_email_required: 'Մուտքագրեք Ձեր Էլ-Փոստը',
  contactForm_message_required: 'Մուտքագրեք Ձեր Հաղորդագրություն',

  contactForm_message_toast_success: 'Ձեր հաղորդագրությունը հաջողությամբ ուղղարկվել է:',
  contactForm_message_toast_error: 'Ձեր հաղորդագրությունը չի ուղղարկվել:',

  details_title: 'Ավելացնել Զամբյուղ',
  detailsText: 'Չափ',
  detailsText1: 'Տեսակ',
  detailsText2: 'Հաստություն',
  detailsText3: 'Կոդ',
  detailsText4: 'Գույն',

  sectionHeading_text: '- ների լայն տեսականի',
  sectionHeading_text1: 'Տվյալ բաժնում դեռևս չկան ապրանքներ',

  sectionContent_title: 'Բաժիններ',
  sectionContent_title1: 'Սեղանածածկ',
  sectionContent_categoryes_title: 'Դասակարգել Ըստ՝',
  sectionContent_options: 'Նոր տեսականի',
  sectionContent_options1: 'Գնի՝ Էժանից - Թանկ',
  sectionContent_options2: 'Գնի՝ Թանկից - էժան',
  sectionContent_options3: 'Ակցիաների',
  sectionContent_price_range_title: 'Արժեք',
  sectionContent_panel_title: 'Չափս',
  sectionContent_panel_title1: 'Նյութ',
  sectionContent_panel_title1_text: 'Գրանիտ',
  sectionContent_panel_title1_text1: 'Փայտ',
  sectionContent_panel_title1_text2: 'ՄԴՖ',
  sectionContent_panel_title1_text3: 'Մարմար',
  sectionContent_panel_title2: 'Հաստություն',
  sectionContent_panel_title3: 'Գույն',
  // sectionContent_panel_title3_text: 'Սպիտակ',
  // sectionContent_panel_title3_text1: 'Կրեմ',
  // sectionContent_panel_title3_text2: 'Սև',
  // sectionContent_panel_title3_text3: 'Խնձոր',
  // sectionContent_panel_title3_text4: 'Վենգե',
  // sectionContent_panel_title3_text5: 'Կանաչ',
  // sectionContent_panel_title3_text6: 'Կապույտ',
  // sectionContent_panel_title3_text7: 'Անտրացիտ',
  sectionContent_panel_title4: 'Արտադրող Երկիր',
  // sectionContent_panel_title4_text: 'Ռուսաստան',
  // sectionContent_panel_title4_text1: 'Իրան (Պարսկաստան)',
  // sectionContent_panel_title4_text2: 'Թուրքիա',
  sectionContent_panel_title5: 'Ծառայություներ',
  // sectionContent_panel_title5_text: 'Կտրել',
  // sectionContent_panel_title5_text1: 'ՊՎՍ',
  sectionContent_panel_title5_text2: 'Առաքում',
  sectionContent_panel_title5_text3: 'Տպագրություն',
  sectionContent_panel_text_all: 'Բոլորը',
  sectionContent_panel_search_text: 'Կոդ',
  sectionContent_empoty_text: 'Ձեր որոնման արդյունքում ոչինչ չի գտնվել:',

  cartBlock_see_more_card: 'Տեսնել Ավելին',
  address: 'Արարատի Մարզ, գյուղ Արբաթ 4-րդ փողոց-54',
  login_dialog_title: 'ՄՈՒՏՔ',
  login_dialog_emaile: 'Էլ-Փոստ',
  login_dialog_password: 'Գաղտնաբառ',
  login_dialog_forget_title: 'Մոռացե՞լ եք գաղտնաբառը',
  login_dialog_emaile_error: 'Խնդրում ենք մուտքագրել ձեր Էլ-Փոստի հասցեն',
  login_dialog_password_error: 'Խնդրում ենք մուտքագրել ձեր գաղտնաբառը',

  dialog_head_back_title: 'Հետ',
  dialog_head_subTitle:
    'Դա լատիներեն բառերի հաջորդականությունն է , որ դրանք տեղակայված են, չեն ստեղծում նախադասություններ:',
  dialog_label: 'Էլ-Փոստ',
  dialog_content_btn_text: 'Վերականգնել գաղտնաբառը',
  dialog_emaile_error: 'Խնդրում ենք մուտքագրել ձեր Էլ-Փոստի հասցեն',
  dialog_emaile_error_text: 'Էլ-Փոստը պետք է լինի վավեր Էլ-Փոստ',

  signUp_dialog_title: 'Գրանցվել',
  signUp_dialog_label: 'Անուն',
  signUp_dialog_label1: 'Ազգանուն',
  signUp_dialog_label2: 'Հեռախոսահամար',
  signUp_dialog_label3: 'Էլ-Փոստ',
  signUp_dialog_label4: 'Քաղաք',
  signUp_dialog_label5: 'Գաղտնաբառ',
  signUp_dialog_label6: 'Կրկնել գաղտնաբառը',
  signUp_dialog_content_btn: 'Գրանցվել',
  signUp_dialog_label_error: 'Մուտքագրեք Ձեր Անունը',
  signUp_dialog_label1_error: 'Մուտքագրեք Ձեր Ազգանունը',
  signUp_dialog_label2_error: 'Մուտքագրեք Ձեր Հեռախոսահամարը',
  signUp_dialog_label3_error: 'Մուտքագրեք Ձեր Էլ-Փոստը',
  signUp_dialog_label4_error: 'Մուտքագրեք Ձեր Քաղաքը',
  signUp_dialog_label5_error: 'Մուտքագրեք Ձեր Գաղտնաբառը',
  signUp_dialog_label6_error1: 'Կրկնեք Ձեր Գաղտնաբառը',
  signUp_dialog_label6_error2: 'Գաղտնաբառերը չեն համընկնում',

  emptyCardContent_title: 'Զամբյուղը Դատարկ է:',
  emptyCardContent_subTitle:
    'Դա, անշուշտ, ամենահռչակավոր տեղադրիչի տեքստը է, նույնիսկ եթե կան տարբեր տարբերակներ, որոնք տարբերվում են այն կարգից, որոնցում լատիներեն բառերը կրկնվում են:',

  productDetails_similarTitle: 'նմանատիպ ապրանքներ',
  productDetails_btn_see_more: 'Տեսնել Ավելին',

  moblie_menu_text: 'Գլխավոր',
  moblie_menu_text1: 'Մեր Մասին',
  moblie_menu_text2: 'Կապ',

  // cartSlice_text: 'Ապրանքը հաջողությամբ ավելացվել է զամբյուղում։',
  // cartSlice_text1: 'Դուք չեք կարող ավելացնել այն զամբյուղ։',
  // cartSlice_text2: 'Հաջողությամբ ջնջվել է։',

  loginSlice_text: 'Դուք հաջողությամբ մուտք եք գործել Ձեր հաշիվ:',
  // loginSlice_text1: 'Սխալ է Էլ-հասցեն կամ ծածկագիրը։',
  // loginSlice_text2: 'Դուք հաջողությամբ գրանցել եք հաշիվ:',
  // loginSlice_text3: 'Խնդրում ենք փորձել կրկին։',

  changeInfo_text: 'Անուն',
  changeInfo_text1: 'Ազգանուն',
  changeInfo_text2: 'Հեռախոսահամար',
  changeInfo_text3: 'Էլ-Փոստ',
  changeInfo_text4: 'Հասցե',
  changeInfo_text5: 'Գաղտնաբառ',
  changeInfo_text6: 'Հաստատել',
  changeInfo_error_text: 'Մուտքագրեք Ձեր Անունը',
  changeInfo_error_text1: 'Մուտքագրեք Ձեր Ազգանունը',
  changeInfo_error_text2: 'Մուտքագրեք Ձեր Հեռախոսահամարը',
  changeInfo_error_text3: 'էլ. փոստը պետք է լինի վավեր էլ. փոստը',
  changeInfo_error_text4: 'Մուտքագրեք Ձեր Էլ-Փոստ',
  changeInfo_error_text5: 'Մուտքագրեք Ձեր Հասցե',
  changeInfo_error_text6: 'Մուտքագրեք Ձեր Գաղտնաբառը',

  changeInfo_password_text: 'Հին Գաղտնաբառը',
  changeInfo_password_text1: 'Գաղտնաբառ',
  changeInfo_password_text2: 'Կրկնել Գաղտնաբառը',
  changeInfo_password_text3: 'Հաստատել',
  changeInfo_password_error_text: 'Մուտքագրեք Ձեր ՀԻն Գաղտնաբառը',
  changeInfo_password_error_text1: 'Մուտքագրեք Ձեր Նոր Գաղտնաբառը',
  changeInfo_password_error_text2: 'Կրկնեք Ձեր Նոր Գաղտնաբառը',
  changeInfo_password_error_text3: 'Գաղտնաբառերը չեն համընկնում',
  changeInfo_password_true: 'Դուք հաջողությամբ Փոխել եք Ձեր գաղտնաբառը։',
  changeInfo_password_false: 'Ձեր գաղտնաբառը սղալ է։',

  userContent_title: 'Անձնական Էջ',
  userContent_title1: 'Իմ Պատվերները',
  userContent_title2: 'Դուրս Գալ',

  orderContent_title: 'Ապրանքի Անվանումը',
  orderContent_title1: 'Արժեքը',
  orderContent_title2: 'Քանակը',
  orderContent_button: 'Պատվիրել',

  till_title: 'Դրամարկղ',
  till_title1: 'Ընդամենը',
  till_title2: 'Ապրանքի Անվանումը',
  till_button: 'Պատվիրել',
  till_input_text: 'Անուն',
  till_input_text1: 'Ազգանուն',
  till_input_text2: 'Հեռախոսահամար',
  till_input_text3: 'Էլ-Փոստ',
  till_input_text4: 'Հասցե',
  till_error_text: 'Մուտքագրեք Ձեր Անունը',
  till_error_text1: 'Մուտքագրեք Ձեր Ազգանունը',
  till_error_text2: 'Մուտքագրեք Ձեր Հեռախոսահամարը',
  till_error_text3: 'էլ. փոստը պետք է լինի վավեր էլ. փոստը',
  till_error_text4: 'Մուտքագրեք Ձեր Էլ-Փոստ',
  till_error_text5: 'Մուտքագրեք Ձեր Հասցե',

  searchInput_text: 'Որոնում',

  myorders_text: 'Ձեր պատվերը հաջողությամբ ընդունվել է։',

  error_title: 'Էջը չի գտնվել:',
  error_title1: 'Գնալ գլխավոր էջ։',

  about_sectionContent_text: 'Ձեր որոնման արդյունքը',

  instocks_text: 'առկա է',
  instocks_text1: 'առկա չէ',

  serviceCart_price: 'հատ',
  serviceCart_price1: 'մետր',
};
