import React, { useState } from 'react';
import './i18n.css';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function Language() {
  const { i18n } = useTranslation();

  const currentLang = i18n.language;

  const changeLang = (lang: any) => () => {
    i18n.changeLanguage(lang);
  };
  const [age, setAge] = useState(currentLang);

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  return (
    <div className="language">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            defaultValue="hy"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Lang"
            onChange={handleChange}
          >
            <MenuItem value={'hy'} onClick={changeLang('hy')}>
              <button className={clsx('LinkActive', currentLang === 'hy' && 'Link')}>ՀԱՅ</button>
            </MenuItem>
            <MenuItem value={'ru'} onClick={changeLang('ru')}>
              <button className={clsx('LinkActive', currentLang === 'ru' && 'Link')}>РУС</button>
            </MenuItem>
            <MenuItem value={'en'} onClick={changeLang('en')}>
              <button className={clsx('LinkActive', currentLang === 'en' && 'Link')}>ENG</button>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
