export default {
  nav_item: 'Главный',
  nav_item1: 'О нас',
  nav_item2: 'Контакт',

  header_buttons_title: 'Вход',
  header_buttons_title1: 'Регистрация',

  head_title: 'Лучшее Для Вас',
  heat_subtitle: 'С нами нет ничего невозможного',
  head_button_title: 'Узнать Больше',

  section1_title: 'Новые ассортименты',

  section2_title: 'Разделы',
  section2_title_subtitle: 'Когда качество работы говорит громче чем слова․․․',
  section2_button_title: 'Узнать Больше',

  footer_info_title: 'АРМАЛИС',
  footer_info_subtitle: 'Высокое качество, быстрое обслуживание и индивидуальный подход.',
  footer_assortment_title: 'Ассортименты',
  footer_services_title: 'Услуги',
  footer_paymont_title: 'Метод оплаты',
  footer_paymont_items: 'Наличные',
  footer_paymont_items1: 'Передача',
  footer_contacts_title: 'Связь',

  about_title: 'Мы работаем с большой любовью и ответственностью',
  aboutUs_title: 'О нас',
  shipment: 'Доставка ',
  printing: 'Печать',
  error_invalid_email: 'С таким эл. адресом есть зарегистрированный пользователь',
  aboutUs_content_text:
    'Компания АрмАлис была основана в <span class="orange">2012թ</span> году директором-основателем <span class="orange">Варданом Маргаряном</span>. Благодаря самоотверженному, добросовестному, дисциплинированному, упорному труду и преданным, опытным специалистам, эта компания за 10 лет расширилась по регионам и городам республики, как говорится в народе росла, процветала, процветала. На протяжении 10 лет благодаря компании АрмАлис тысячи армянских мужчин оставались рядом со своими семьями и не могли зарабатывать деньги за рубежом. На протяжении <span class="orange">10 лет</span> многие квартиры дебютировали именно с мебелью из наших материалов, которые импортируются из России, из Персии и других стран. Качество и быстрое обслуживание гарантируем, а разнообразие ассортимента обеспечивает. Мы проявляем индивидуальный подход к каждому клиенту.',
  about_shipment_text:
    'Услуга доставки доступна по всей территории Республики Армения.Доставка в Ереване бесплатна, а стоимость доставки в регионы варьируется в зависимости от расстояния.',
  about_printing_text:
    'Делаем печать ламинат, стекло, холст, кожа, МДФ, ПВХ. Принимаем индивидуальные и оптовые заказы.',
  achievement_text:
    'Новые достижения ,новые рынки, новые партнеры. АрмАлис-компания, которая не нуждается в рекламе. Компания АрмАлис-это семья, которая обеспечит вас высококачественными материалами сегодня, завтра и навсегда.',

  contacs_title: 'Мы работаем с большой любовью и ответственностью',
  how_find_us: 'Как найти нас ?',
  feedBack_title: 'обратная связь',
  work_day: 'РАБОЧИЙ ДЕНЬ В АРМАЛИС',
  address: 'Араратская область, село Арбат 4-я улица-54',
  contactForm_name: 'Имя',
  contactForm_lastname: 'Фамилия',
  contactForm_phone: 'Номер',
  contactForm_email: 'Эл. Почта',
  contactForm_message: 'Сообщение',
  contactForm_submit: 'Отправить',

  contactForm_name_required: 'Введите Свое Имя',
  contactForm_lastname_required: 'Введите Свою Фамилию',
  contactForm_phone_required: 'Введите Свой Номер Телефона',
  contactForm_email_required: 'Введите Свой Адрес Электронной Почты',
  contactForm_message_required: 'Введите Ваше Сообщение',

  contactForm_message_toast_success: 'Ваше сообщение было успешно отправлено.',
  contactForm_message_toast_error: 'Ваше сообщение не было отправлено.',

  details_title: 'Добавить в Корзину',
  detailsText: 'Размер',
  detailsText1: 'Тип',
  detailsText2: 'Толщина',
  detailsText3: 'Код',
  detailsText4: 'Цвет',

  sectionHeading_text: 'широкий ассортимент',
  sectionHeading_text1: 'Ձեր ընտրած բաժնում չկան ապրանքներ',

  sectionContent_title: 'Разделы',
  sectionContent_title1: 'Столешница',
  sectionContent_categoryes_title: 'Классифицировать По:',
  sectionContent_options: 'Новый ассортимент',
  sectionContent_options1: 'Цена: От Дешевого До Дорогого',
  sectionContent_options2: 'Цена: От Дорогого До Дешевого',
  sectionContent_options3: 'Акции',
  sectionContent_price_range_title: 'Стоимость',
  sectionContent_panel_title: 'Размер',
  sectionContent_panel_title1: 'Материал',
  sectionContent_panel_title1_text: 'Гранит',
  sectionContent_panel_title1_text1: 'Дерево',
  sectionContent_panel_title1_text2: 'МДФ',
  sectionContent_panel_title1_text3: 'Мрамор',
  sectionContent_panel_title2: 'Толщина',
  sectionContent_panel_title3: 'Цвет',
  sectionContent_panel_title3_text: 'Белый',
  sectionContent_panel_title3_text1: 'Кремывй',
  sectionContent_panel_title3_text2: 'Черный',
  sectionContent_panel_title3_text3: 'Яблоко',
  sectionContent_panel_title3_text4: 'Венге',
  sectionContent_panel_title3_text5: 'Зеленый',
  sectionContent_panel_title3_text6: 'Синий',
  sectionContent_panel_title3_text7: 'Антрацит',
  sectionContent_panel_title4: 'Страна-Производитель',
  sectionContent_panel_title4_text: 'Россия',
  sectionContent_panel_title4_text1: 'Иран',
  sectionContent_panel_title4_text2: 'Турция',
  sectionContent_panel_title5: 'Услуги',
  sectionContent_panel_title5_text: 'Вырезать',
  sectionContent_panel_title5_text1: 'ПВС',
  sectionContent_panel_title5_text2: 'Отгрузка',
  sectionContent_panel_title5_text3: 'Печать',
  sectionContent_panel_text_all: 'Все',
  sectionContent_panel_search_text: 'Код',
  sectionContent_empoty_text: 'Ваш поиск ничего не дал.',

  cartBlock_see_more_card: 'Узнать больше',

  login_dialog_title: 'ВХОД',
  login_dialog_emaile: 'Эл. Почта',
  login_dialog_password: 'Пароль',
  login_dialog_forget_title: 'Забыли пароль?',
  login_dialog_emaile_error: 'Пожалуйста, введите свой адрес электронной почты',
  login_dialog_password_error: 'Пожалуйста введите ваш пароль',

  dialog_head_back_title: 'Назад',
  dialog_head_subTitle:
    'Это порядок латинских слов, в котором они расположены, они не составляют предложений.',
  dialog_label: 'Эл. Почта',
  dialog_content_btn_text: 'Сброс пароля',
  dialog_emaile_error: 'Пожалуйста, введите свой адрес электронной почты',
  dialog_emaile_error_text: 'Электронная почта должна быть действительной.',

  signUp_dialog_title: 'регистр',
  signUp_dialog_label: 'Имя',
  signUp_dialog_label1: 'Фамилия',
  signUp_dialog_label2: 'Номер телефона',
  signUp_dialog_label3: 'Эл. Почта',
  signUp_dialog_label4: 'Город',
  signUp_dialog_label5: 'Пароль',
  signUp_dialog_label6: 'Повторить пароль',
  signUp_dialog_content_btn: 'Регистр',
  signUp_dialog_label_error: 'Введите Свое Имя',
  signUp_dialog_label1_error: 'Введите Свою Фамилию',
  signUp_dialog_label2_error: 'Введите Свой Номер Телефона',
  signUp_dialog_label3_error: 'Введите Свой Адрес Электронной Почты',
  signUp_dialog_label4_error: 'Введите Свой Город',
  signUp_dialog_label5_error: 'Введите Свой Пароль',
  signUp_dialog_label6_error1: 'Повторите Свой Пароль',
  signUp_dialog_label6_error2: 'Пароли не совпадают',

  emptyCardContent_title: 'Корзина пуста.',
  emptyCardContent_subTitle:
    'Это, безусловно, самый популярный текст установщика, даже если есть разные варианты, отличные от порядка, в котором латинские слова повторяются.',

  productDetails_similarTitle: 'похожие продукты',
  productDetails_btn_see_more: 'Узнать Больше',

  moblie_menu_text: 'Главный',
  moblie_menu_text1: 'О нас',
  moblie_menu_text2: 'Контакт',

  cartSlice_text: 'Товар успешно добавлен в корзину.',
  cartSlice_text1: 'Вы не можете добавить его в корзину.',
  cartSlice_text2: 'Успешно удалено.',

  loginSlice_text: 'Вы успешно вошли в свою учетную запись.',
  loginSlice_text1: 'Неверный адрес электронной почты или пароль.',
  loginSlice_text2: 'Вы успешно зарегистрировали аккаунт.',
  loginSlice_text3: 'Пожалуйста, попробуйте еще раз.',

  changeInfo_text: 'Имя',
  changeInfo_text1: 'Фамилия',
  changeInfo_text2: 'Телефон',
  changeInfo_text3: 'Эл. адрес',
  changeInfo_text4: 'Адрес',
  changeInfo_text5: 'Пароль',
  changeInfo_text6: 'Обновить профиль',
  changeInfo_error_text: 'Введите ваше имя',
  changeInfo_error_text1: 'Введите вашу фамилию',
  changeInfo_error_text2: 'Введите свой номер телефона',
  changeInfo_error_text3: 'Электронная почта должна быть действительной',
  changeInfo_error_text4: 'Введите адрес электронной почты',
  changeInfo_error_text5: 'Введите свой адрес',
  changeInfo_error_text6: 'Введите свой пароль',

  changeInfo_password_text: 'Старый пароль',
  changeInfo_password_text1: 'Введите новый пароль',
  changeInfo_password_text2: 'Введите повторно новый пароль',
  changeInfo_password_text3: 'Обновить пароль',
  changeInfo_password_error_text: 'Введите свой старый пароль',
  changeInfo_password_error_text1: 'Введите новый пароль',
  changeInfo_password_error_text2: 'Повторите свой новый пароль',
  changeInfo_password_error_text3: 'Пароли не совпадают',
  changeInfo_password_true: 'Вы успешно изменили ваш пароль.',
  changeInfo_password_false: 'Ваш пароль неверен.',

  userContent_title: 'Мой профиль',
  userContent_title1: 'Мои заказы',
  userContent_title2: 'Выйти',

  orderContent_title: 'Наименование товара',
  orderContent_title1: 'Стоимость',
  orderContent_title2: 'Количество',
  orderContent_button: 'Заказать',

  till_title: 'Касса',
  till_title1: 'Всего',
  till_title2: 'Название Продукта',
  till_button: 'Заказать',
  till_input_text: 'Имя',
  till_input_text1: 'Фамилия',
  till_input_text2: 'Телефон',
  till_input_text3: 'Эл. адрес',
  till_input_text4: 'Адрес',
  till_error_text: 'Введите ваше имя',
  till_error_text1: 'Введите вашу фамилию',
  till_error_text2: 'Введите свой номер телефона',
  till_error_text3: 'Электронная почта должна быть действительной',
  till_error_text4: 'Введите адрес электронной почты',
  till_error_text5: 'Введите свой адрес',

  searchInput_text: 'Поиск',

  myorders_text: 'Ваш заказ успешно размещен.',

  error_title: 'Страница не найдена',
  error_title1: 'Перейдите на главную страницу.',

  about_sectionContent_text: 'Ваш результат поиска',

  instocks_text: 'доступен',
  instocks_text1: 'нет в наличии',

  serviceCart_price: 'шт',
  serviceCart_price1: 'метр',
};
