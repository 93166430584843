import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loadText } from '../../../redux/texts/texts';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import video2 from '../../../images/Video2.MOV';
import './WorkProcess.css';

export const WorkProcess = () => {
  const dispatch: AppDispatch = useDispatch();
  // const text = useSelector((state: any) => state?.text?.text);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadText());
  }, []);
  const { t } = useTranslation();

  return (
    <div className="workProcess container">
      <div className="workProcess_text_block">
        <p className="workProcess_text">{t('work_day')}</p>
      </div>
      <div className="wrkProcess_content_img">
        <div>
          <video width="100%" height="600" controls>
            <source src={video2} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};
